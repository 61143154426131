.nav {
  width: 100%;
  /* top: 0; */
  display: block;
  background: rgba(0, 0, 0, 0.87);
  text-align: center;
  height: 70px;
  /* min-height: 64px; */
  color: white;
  position: sticky;
  z-index: 3;
  box-sizing: border-box;
  transition: 0.5s;
}

.nav_title {
  margin: 0;
  padding: 1rem;
}

.nav_title-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
