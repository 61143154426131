.news_container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  /* margin-top: 3rem; */
  width: 70%;
  height: 25rem;
}

.news_signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffc071;
  padding: 64px 24px;
  text-align: left;
  z-index: 2;
  position: relative;
  top: 50px;
  left: 50px;
  width: 100%;
}

.signup_title {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  margin-bottom: 0.35em;
}

.signup_tagline {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.334;
  margin-bottom: 1rem;
}

.signup_input {
  font-size: 16px;
  padding: 16px;
  width: calc(100% - 32px);
  border: none;
}

.signup_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  color: #fff;
  border-radius: 0;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.875rem;
  box-shadow: none;
  width: 100%;
  background-color: black;
  margin-top: 1rem;
}

.signup_image_container {
  width: 100%;
  /* contain: content; */
  z-index: 1;
  right: 30px;
  background-image: url(../../media/productCTAImageDots.png);
}

.signup_image {
  width: 90%;
  transform: scaleX(-1);
  margin-top: 30px;
  overflow: visible;
}

@media only screen and (max-width: 600px) {
  .news_container {
    flex-direction: column;
    margin-bottom: 16rem;
    width: 80%;
  }

  .news_signup {
    left: 0;
    text-align: center;
    width: 85%;
  }
}
