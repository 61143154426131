.hero_container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.6)
    ),
    url("../../media//heroImage.jpg");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  color: white;
}

.hero_text_container {
  padding-top: 12%;
}

.hero_title {
  margin: 0;
  font-size: 65px;
}

.hero_underscore {
  height: 4px;
  width: 20%;
  display: block;
  margin: 8px auto 0;
  background-color: #ff3366;
}

.hero_tagline {
  font-size: 25px;
  margin: 80px 0 32px 0;
}

.cta_register {
  width: 100px;
  height: 30px;
  background-color: #ff3366;
  border: none;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  padding: 16px 40px;
  font-size: 1rem;
  box-shadow: none;
  min-width: 200px;
  height: auto;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cta_register:hover {
  text-decoration: none;
  background-color: #e62958;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.hero_cta_tagline {
  font-size: 14px;
  margin-top: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.discov-more {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.discov-more:hover {
  color: #ff3366;
}

@media only screen and (max-width: 600px) {
  .hero_title {
    font-size: 60px;
    padding: 0 15px;
  }
}
