.App {
  text-align: center;
}

.smooth-scroll {
  display: block;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

.error_msg {
  font-size: 175px;
}

@media only screen and (max-width: 600px) {
  .error_msg {
    font-size: 90px;
  }
}

.home_btn {
  width: 100px;
  height: 30px;
  background-color: #ff3366;
  border: none;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  padding: 16px 40px;
  font-size: 1rem;
  box-shadow: none;
  min-width: 200px;
  height: auto;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-bottom: 4rem;
}

.home_btn:hover {
  text-decoration: none;
  background-color: #e62958;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
