.who_container {
  padding: 3rem;
}

.who_items {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  margin-top: 5rem;
}

.who_title {
  margin: 0;
}

.who_underscore {
  height: 4px;
  width: 10%;
  display: block;
  margin: 8px auto 0;
  background-color: #ff3366;
}

.who_icon {
  height: 200px;
}

@media only screen and (max-width: 1020px) {
  .who_icon {
    height: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .who_items {
    flex-direction: column;
  }
}
